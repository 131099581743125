import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import MainLayout from '../layaouts/MainLayout/MainLayout';
import image404 from '../assets/404.png';
import Button from '../components/Forms/Button/Button';

function Page404() {
  const intl = useIntl();
  const breakpoints = useBreakpoint();

  return (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={image404}
          alt="404"
          style={{ height: 317, width: 477, margin: 50 }}
        />
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: 40,
            textAlign: 'center',
            color: '#003F6E',
            margin: 'o auto',
          }}
        >
          {intl.formatMessage({ id: '404_title' })}
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: 15,
            textAlign: 'center',
            maxWidth: 300,
            padding: 10,
            margin: 'o auto',
          }}
        >
          {intl.formatMessage({ id: '404_message' })}
        </div>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            label={intl.formatMessage({ id: '404_btn_label' })}
            style={{
              backgroundColor: '#D75F2B',
              color: 'white',
              margin: 'o auto',
              marginTop: 10,
              marginBottom: 125,
            }}
          />
        </Link>

      </div>
    </MainLayout>
  );
}

export default Page404;
